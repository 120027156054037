import './App.css';

function App() {
  return (
    <div className="App-wallpaper">
      <header className="App-header">
        <h1>GenesisCipher Labs</h1>
      </header>
      <main className="App-main">
        <h2>Coming Soon...</h2>
      </main>
      <footer className="App-footer">
        <p>Copyright © {new Date().getFullYear()} GenesisCipher Labs - All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
